.btn {
  @apply relative inline-flex cursor-pointer items-center justify-center gap-02 px-6 py-3 font-montserrat text-xs font-medium uppercase leading-[14px] tracking-[2.4px] duration-500 [&_svg]:duration-500;
}

.btn::after {
  @apply absolute h-[calc(100%-8px)] w-[calc(100%-8px)] rounded-sm border-[0.4px] border-transparent duration-500 content-[''] disabled:border-0;
}

/* primary button */
.btn.primary {
  @apply h-11 rounded bg-charcoal text-silk after:hover:border-silk/50 active:bg-[#252424] disabled:bg-charcoal/25 [&_svg]:fill-silk;
}

.btn.primary.stroke {
  @apply [&_svg]:fill-none [&_svg]:stroke-silk;
}

.btn.primary.dark {
  @apply bg-silk text-charcoal after:hover:border-charcoal active:bg-sandstone disabled:opacity-25 [&_svg]:fill-charcoal;
}

.btn.primary.dark.stroke {
  @apply [&_svg]:fill-none [&_svg]:stroke-charcoal;
}

.btn.primary.small {
  @apply h-9;
}

/* secondary button */
.btn.secondary {
  @apply h-11 rounded border border-charcoal text-charcoal after:hover:border-charcoal active:after:border-transparent disabled:opacity-25 [&_svg]:fill-charcoal;
}

.btn.secondary.stroke {
  @apply [&_svg]:fill-none [&_svg]:stroke-charcoal;
}

.btn.secondary.dark {
  @apply border-silk text-silk after:hover:border-silk active:border-sandstone active:text-sandstone active:after:border-transparent disabled:opacity-25 [&_svg]:fill-silk [&_svg]:active:fill-sandstone;
}

.btn.secondary.dark.stroke {
  @apply [&_svg]:fill-none [&_svg]:stroke-silk [&_svg]:active:fill-none [&_svg]:active:stroke-sandstone;
}

.btn.secondary.small {
  @apply h-9;
}

/* tertiary button */
.btn.tertiary {
  @apply h-11 px-0 text-charcoal disabled:opacity-25 [&_svg]:fill-charcoal;
}

.btn.tertiary.stroke {
  @apply [&_svg]:fill-none [&_svg]:stroke-charcoal;
}

.btn.tertiary.dark {
  @apply text-silk disabled:opacity-25 [&_svg]:fill-silk;
}

.btn.tertiary.dark.stroke {
  @apply [&_svg]:fill-none [&_svg]:stroke-silk;
}

.btn.tertiary.small {
  @apply h-9;
}

/* Control button */

.btn.control {
  @apply h-12 rounded-full bg-silk after:rounded-full after:border-charcoal hover:bg-sandstone active:bg-silk disabled:opacity-25 disabled:after:border disabled:after:border-charcoal [&_*:not([fill="none"])]:fill-charcoal;
}

.btn.control.dark {
  @apply bg-charcoal text-silk after:border-silk hover:bg-[#252424] active:bg-charcoal [&_*:not([fill="none"])]:fill-silk;
}

.btn.control.small {
  @apply h-8;
}

/* pagination button */
.btn.pagination {
  @apply pointer-events-auto h-16 bg-charcoal after:w-full after:border-silk [&_*:not([fill="none"])]:fill-silk;
}

.btn.pagination.silk {
  @apply bg-silk after:border-charcoal [&_*:not([fill="none"])]:fill-charcoal;
}

.btn.pagination.coastal {
  @apply bg-coastal;
}

.btn.pagination.mountain {
  @apply bg-mountain;
}

.btn.pagination.urban {
  @apply bg-urban;
}

.btn.pagination.left {
  @apply ltr:rounded-r-full ltr:pl-4 ltr:after:right-1 ltr:after:rounded-r-full rtl:rounded-l-full rtl:rounded-r-none rtl:after:left-1 rtl:after:rounded-l-full rtl:after:rounded-r-none ltr:rtl:rounded-l-full;
  svg {
    @apply rtl:rotate-180;
  }
}

.btn.pagination.right {
  @apply ltr:rounded-l-full ltr:pr-4 ltr:after:left-1 ltr:after:rounded-l-full rtl:rounded-r-full rtl:pl-4 rtl:after:right-1 rtl:after:rounded-r-full;
  svg {
    @apply rtl:rotate-0;
  }
}
