@font-face {
  font-family: 'Kepler Std Display';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../public/fonts/kepler-light.woff2') format('woff2');
}

@font-face {
  font-family: 'Kepler Std';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../public/fonts/kepler-normal.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/fonts/montserrat-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../public/fonts/montserrat-medium.woff2') format('woff2');
}

.font-montserrat,
.font-kepler,
.font-kepler-display {
  @apply antialiased;
}
