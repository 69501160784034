.input-group {
  @apply flex flex-col gap-2;
}

.input-group .input {
  @apply block w-full rounded px-4 py-3 font-montserrat text-body-03 font-normal leading-body-03 tracking-body-03 disabled:opacity-75;
}

.input-group .input[type='text'] {
  @apply border border-transparent;
}

.input-group .checkbox {
  @apply flex items-center;
}
.input-group .radio {
  @apply flex;
}

.input-group .checkbox .label {
  @apply mb-0 font-medium normal-case;
}

.input-group .radio .label {
  @apply mb-0 font-normal normal-case;
}

.input-group .input[type='checkbox'],
.input-group .input[type='radio'] {
  @apply mr-2 w-auto accent-black;
}

.input-group.sandstone .input[type='text'] {
  @apply border border-charcoal/0 bg-sandstone/60 outline-none;
}

.input-group.silk .input[type='text'] {
  @apply bg-silk/60;
}

.input-group .label {
  @apply mb-2 block text-interactive-02 font-semibold leading-interactive-02 text-charcoal;
}

.input-group .multi-checkbox {
  @apply flex items-center;
}

/* Placeholder */
.input-group .input {
  @apply placeholder:font-montserrat placeholder:text-body-03 placeholder:font-normal placeholder:leading-body-03 placeholder:tracking-body-03 placeholder:text-charcoal/75;
}
.input-group .datepicker .popover-title.placeholder,
.input-group .dropdown .popover-title.placeholder {
  @apply font-montserrat text-body-03 font-normal leading-[14px] tracking-body-03 text-charcoal/75;
}

/* Dropdown */
.input-group .datepicker,
.input-group .dropdown {
  @apply border-none p-0;
}

.input-group .datepicker .main-button,
.input-group .dropdown .main-button {
  @apply h-[48px] rounded-[4px] border border-charcoal/0 bg-sandstone/60 transition-all xxl:h-[54px];
}

.input-group.error .datepicker .main-button,
.input-group.error .dropdown .main-button {
  @apply border-messagingRed;
}

.input-group .datepicker .popover-title,
.input-group .dropdown .popover-title {
  @apply font-montserrat text-body-03 font-normal leading-[14px] tracking-body-03;
}

.input-group.error .datepicker .popover-title,
.input-group.error .dropdown .popover-title {
  @apply text-messagingRed;
}

.input-group .datepicker .content,
.input-group .dropdown .content {
  @apply rounded border border-charcoal bg-silk shadow-input;
}

.input-group .datepicker.open .content,
.input-group .dropdown.open .content {
  @apply drop-shadow-lg;
}

.input-group .dropdown.open .option {
  @apply hover:bg-bone/40;
}

.input-group .dropdown.open .main-button,
.input-group .datepicker.open .main-button {
  @apply border-charcoal transition-all;
}
/* End Dropdown */

.input-group.error .label {
  @apply text-messagingRed;
}

.input-group.error .input {
  @apply border-messagingRed;
}

.input-group.error .error-message {
  @apply font-normal text-messagingRed;
}

.input-group .input.number-input {
  @apply flex;
}

.input-group .input.number-input input[type='number']::-webkit-outer-spin-button,
.input-group .input.number-input input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .input.number-input input[type='number'] {
  @apply bg-transparent;
  width: 80%;
  text-align: center;
  -moz-appearance: textfield;
  outline: none;
  border: none;
}

.input-group .input.number-input input[type='number']:focus-visible {
  outline: none;
  border: none;
}

.input-group .input.datepicker {
  @apply p-0;
}

.radio-group {
  @apply flex;
}
.input-group .radio-group,
.input-group .multi-checkbox {
  @apply cursor-pointer;
}

.input-group:has(input:disabled),
.input-group:has(input:disabled) .number-input > *,
.input-group:has(input:disabled) label:has(input:disabled),
button:disabled {
  @apply cursor-not-allowed;
}

.input-group .input.number-input input[type='number']:disabled {
  @apply bg-transparent;
}

.select {
  @apply grow bg-transparent px-4 py-2.5 font-montserrat text-small-paragraph font-medium uppercase tracking-[.96px] text-white outline-none placeholder:text-white;
}

.input-common {
  @apply rounded-full border border-white/25 p-2 font-montserrat text-small-paragraph uppercase tracking-[.96px] text-white outline-none backdrop-blur-[13px];
  background: rgba(255, 255, 255, 0.02);
}

.input-common option {
  @apply rounded-full border text-small-paragraph uppercase tracking-[.96px] text-black outline-none;
}

input[type='color'] {
  @apply rounded-full border border-white/25 p-0 text-small-paragraph uppercase tracking-[.96px] text-white outline-none backdrop-blur-[13px];
  background: rgba(255, 255, 255, 0.02);
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  @apply rounded-full border border-white/25 text-small-paragraph uppercase tracking-[.96px] text-white outline-none backdrop-blur-[13px];
  background: rgba(255, 255, 255, 0.02);
}

input[type='checkbox'] {
  @apply relative !size-6 cursor-pointer appearance-none !p-0 transition-opacity before:absolute before:size-full before:content-checkbox after:absolute after:size-full after:opacity-0 after:content-checkboxChecked;
}

input[type='checkbox']:checked {
  @apply before:opacity-0 after:opacity-100;
}

.input[type='text']:focus-visible {
  @apply border border-charcoal;
}

textarea.input {
  @apply !rounded-lg border border-charcoal/25 bg-silk;
}

textarea.input:focus-visible {
  @apply border-charcoal outline-none;
}
