/* Datepicker */

.react-datepicker {
  @apply relative flex w-full gap-10 px-4 py-8;
}

.react-datepicker__header {
  @apply relative text-center;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply font-montserrat text-body-04 font-normal leading-body-04 tracking-body-04 text-charcoal;
}

.react-datepicker__month-container {
  @apply w-full;
}

.datepicker.multiple-months .react-datepicker__month-container .next-buttons button {
  @apply pointer-events-none opacity-0;
}

.datepicker.multiple-months .react-datepicker__month-container:last-child .prev-buttons button {
  @apply pointer-events-none opacity-0;
}

.datepicker.multiple-months .react-datepicker__month-container:last-child .next-buttons button {
  @apply pointer-events-auto opacity-100;
}

.react-datepicker__month {
  @apply flex flex-col gap-2;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply flex justify-between whitespace-nowrap;
}

.react-datepicker__day-names {
  @apply mb-3 mt-4;
}
.react-datepicker__day-name {
  @apply w-full font-montserrat text-interactive-02 font-medium leading-interactive-02 text-charcoal;
}
.react-datepicker__day,
.react-datepicker__time-name {
  @apply flex h-10 items-center justify-center font-montserrat text-body-04 font-normal leading-body-04 tracking-body-04 text-charcoal lg:w-full;
}

.react-datepicker__day-name {
  @apply font-medium;
}

.react-datepicker__day--outside-month {
  @apply text-charcoal/25;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  @apply cursor-pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply bg-sandstone/40;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  @apply text-charcoal;
}

.react-datepicker__day--in-range.react-datepicker__day--range-start:not(.react-datepicker__day--outside-month),
.react-datepicker__day--in-range.react-datepicker__day--range-end:not(.react-datepicker__day--outside-month),
.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start:not(
    .react-datepicker__day--outside-month
  ),
.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end:not(
    .react-datepicker__day--outside-month
  ) {
  @apply bg-bone;
}
.react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) {
  @apply bg-sandstone/40;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  @apply bg-sandstone/40;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  @apply bg-sandstone/40;
}

.react-datepicker__day {
  @apply w-10;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  @apply cursor-default text-charcoal/25;
}

/* .react-datepicker__day--disabled.react-datepicker__day--today,
.react-datepicker__day--today.react-datepicker__day {
  @apply bg-sandstone;
} */

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  @apply bg-transparent;
}

.react-datepicker__input-container {
  @apply flex h-10 w-full flex-row-reverse items-center rounded-sm border-b border-white/25 px-4 py-3;
}

.react-datepicker__aria-live {
  @apply absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0;
  clip-path: circle(0);
}
