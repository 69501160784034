:root {
  --spacing-01: 4px; /* 01 */
  --spacing-02: 8px; /* 02 */
  --spacing-03: 12px; /* 03 */
  --spacing-04: 16px; /* 04 */
  --spacing-05: 24px; /* 05 */
  --spacing-06: 32px; /* 06 */
  --spacing-07: 40px; /* 07 */
  --spacing-08: 56px; /* 08 */
  --spacing-09: 72px; /* 09 */

  @screen md {
    --spacing-03: 16px; /* 03 */
    --spacing-04: 20px; /* 04 */
    --spacing-08: 72px; /* 08 */
    --spacing-09: 88px; /* 09 */
  }

  @screen lg {
    --spacing-04: 24px; /* 04 */
    --spacing-05: 32px; /* 05 */
    --spacing-06: 40px; /* 06 */
    --spacing-07: 64px; /* 07 */
    --spacing-08: 88px; /* 08 */
    --spacing-09: 112px; /* 09 */
  }

  @screen xl {
    --spacing-06: 48px; /* 06 */
    --spacing-07: 80px; /* 07 */
    --spacing-08: 120px; /* 08 */
    --spacing-09: 136px; /* 09 */
  }
}
