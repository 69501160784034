@tailwind components;

:root {
  /*
    Title
  */
  --font-size-title-title-01: 56px;
  --font-line-height-title-title-01: 56px;

  --font-size-title-title-02: 40px;
  --font-line-height-title-title-02: 48px;

  --font-size-title-title-03: 32px;
  --font-line-height-title-title-03: 40px;

  --font-size-title-title-04: 24px;
  --font-line-height-title-title-04: 32px;

  --font-size-title-title-05: 20px;
  --font-line-height-title-title-05: 24px;

  /*
    Body
  */
  --font-size-body-body-01: 16px;
  --font-line-height-body-body-01: 24px;
  --letter-spacing-body-01: 0.16px;

  --font-size-body-body-02: 14px;
  --font-line-height-body-body-02: 20px;
  --letter-spacing-body-02: 0.14px;

  --font-size-body-body-03: 14px;
  --font-line-height-body-body-03: 20px;
  --letter-spacing-body-03: 0.14px;

  --font-size-body-body-04: 12px;
  --font-line-height-body-body-04: 16px;
  --letter-spacing-body-04: 0.12px;

  /*
  Interactive
  */
  --font-size-interactive-interactive-01: 12px;
  --font-line-height-interactive-01: 14px;
  --letter-spacing-interactive-01: 2.4px;

  --font-size-interactive-interactive-02: 14px;
  --font-line-height-interactive-02: 14px;

  /*
    Label
  */

  --font-size-label-label-01: 12px;
  --font-line-height-label-label-01: 16px;
  --letter-spacing-label-01: 0.48px;

  @screen md {
    /*
      Title
    */
    --font-size-title-title-01: 56px;
    --font-line-height-title-title-01: 56px;

    --font-size-title-title-02: 40px;
    --font-line-height-title-title-02: 48px;

    --font-size-title-title-03: 32px;
    --font-line-height-title-title-03: 40px;

    --font-size-title-title-04: 24px;
    --font-line-height-title-title-04: 32px;

    --font-size-title-title-05: 20px;
    --font-line-height-title-title-05: 24px;

    /*
      Body
    */
    --font-size-body-body-01: 20px;
    --font-line-height-body-body-01: 28px;
    --letter-spacing-body-01: 0.2px;

    --font-size-body-body-02: 14px;
    --font-line-height-body-body-02: 24px;
    --letter-spacing-body-02: 0.14px;

    /*
    Label
  */
    --font-size-label-label-01: 14px;
    --font-line-height-label-label-01: 20px;
    --letter-spacing-label-01: 0.56px;
  }

  @screen lg {
    /*
      Title
    */
    --font-size-title-title-01: 80px;
    --font-line-height-title-title-01: 88px;

    --font-size-title-title-02: 48px;
    --font-line-height-title-title-02: 56px;

    --font-size-title-title-03: 40px;
    --font-line-height-title-title-03: 48px;

    --font-size-title-title-04: 32px;
    --font-line-height-title-title-04: 40px;

    --font-size-title-title-05: 24px;
    --font-line-height-title-title-05: 32px;

    /*
      Body
    */
    --font-size-body-body-01: 24px;
    --font-line-height-body-body-01: 32px;
    --letter-spacing-body-01: 0.24px;

    --font-size-body-body-02: 16px;
    --font-line-height-body-body-02: 28px;
    --letter-spacing-body-02: 0.16px;
  }

  @screen xxl {
    /*
      Title
    */
    --font-size-title-title-01: 88px;
    --font-line-height-title-title-01: 96px;

    --font-size-title-title-02: 64px;
    --font-line-height-title-title-02: 72px;

    --font-size-title-title-03: 48px;
    --font-line-height-title-title-03: 56px;

    --font-size-title-title-04: 40px;
    --font-line-height-title-title-04: 40px;

    --font-size-title-title-05: 32px;
    --font-line-height-title-title-05: 40px;

    /*
      Body
    */
    --font-size-body-body-02: 20px;
    --font-line-height-body-body-02: 32px;
    --letter-spacing-body-02: 0.2px;

    --font-size-body-body-03: 16px;
    --font-line-height-body-body-03: 28px;
    --letter-spacing-body-03: 0.16px;

    --font-size-body-body-04: 14px;
    --font-line-height-body-body-04: 20px;
    --letter-spacing-body-04: 0.14px;
  }
}

/*
  Display
*/

@layer components {
  /*
  Title
*/

  .title-01 {
    @apply font-kepler-display text-title-01 font-light leading-title-01;
  }

  .title-02 {
    @apply font-kepler-display text-title-02 font-light leading-title-02;
  }

  .title-03 {
    @apply font-kepler-display text-title-03 font-light leading-title-03;
  }

  .title-04 {
    @apply font-kepler text-title-04 font-light leading-title-04;
  }

  .title-05 {
    @apply font-kepler text-title-05 font-light leading-title-05;
  }

  /*
  Body
*/

  .body-01 {
    @apply font-montserrat text-body-01 font-normal leading-body-01 tracking-body-01;
  }

  .body-02 {
    @apply font-montserrat text-body-02 font-normal leading-body-02 tracking-body-02;
  }

  .body-03 {
    @apply font-montserrat text-body-03 font-normal leading-body-03 tracking-body-03;
  }

  .body-04 {
    @apply font-montserrat text-body-04 font-normal leading-body-04 tracking-body-04;
  }

  /*
  Interactive
*/

  .interactive-01 {
    @apply font-montserrat text-interactive-01 font-medium uppercase leading-interactive-01 tracking-interactive-01;
  }

  .interactive-02 {
    @apply font-montserrat text-interactive-02 font-medium leading-interactive-02;
  }

  /*
  Label
*/
  .label-01 {
    @apply font-montserrat text-label-01 font-normal uppercase leading-label-01 tracking-label-01;
  }
}
/*
  delete me
*/
.main-heading {
  @apply text-[5rem] leading-[7.5rem] sm:text-[6.25rem] sm:leading-[9.375rem] lg:text-[7.5rem] lg:leading-[11.25rem];
}

.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  @apply font-montserrat font-semibold;
}

.text-h1 {
  @apply text-[3rem] leading-[4rem] sm:text-[4rem] sm:leading-[4.5rem] lg:text-[4.5rem] lg:leading-[6rem];
}

.text-h2 {
  @apply text-[2.5rem] leading-[3.5rem] sm:text-[3.25rem] sm:leading-[4.25rem] lg:text-[3.75rem] lg:leading-[5rem];
}

.text-h3 {
  @apply text-[2rem] leading-[2.625rem] sm:text-[2.625rem] sm:leading-[3.5rem] lg:text-[3rem] lg:leading-[4.5rem];
}

.text-h4 {
  @apply text-heading5 leading-[2.25rem] sm:text-[2.125rem] sm:leading-[3rem] lg:text-[2.25rem] lg:leading-[3.5rem];
}

.text-h5 {
  @apply text-heading6 leading-heading6 sm:text-heading5 sm:leading-heading3 lg:text-heading4 lg:leading-heading3;
}

.text-h6 {
  @apply text-[1.125rem] leading-xl-paragraph sm:text-[1.25rem] sm:leading-[2rem] lg:text-heading6 lg:leading-[2rem];
}

.text-subheading {
  @apply text-paragraph font-semibold leading-paragraph tracking-[0.01em] lg:text-large-paragraph lg:leading-xl-paragraph;
}
